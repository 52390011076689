$(document).ready(function() {
	console.log("hello 👋");
});

// ****************************************
// NAV TOGGLE
// ****************************************
$(".js-nav-toggle").click(function () {
	$(".js-nav-toggle").toggleClass('header__nav-toggle--active');
	$(".header__nav--mobile").toggleClass('header__nav--mobile--active');
});

// ****************************************
// POPUP CLOSE
// ****************************************
function popup() {
	if ($(".popup").length) {
		$(".js-popup-close").click(function () {
			$(this).closest('.popup').removeClass("popup--active")
		});

		window.setTimeout(function(){
			$('.popup').addClass('popup--active');
		}, 3000);
	}
}
 

// ****************************************
// COLUMN SORTER FOR GRID LAYOUT
// ****************************************
function cols() {
  var dataCols = $(".cols").attr("data-cols");
	var columns = parseInt(dataCols);
  
	var count = 0
	var content = $(".cols > *");
  
  	$(".cols").before("<div class='cols-columns'></div>");

	content.each(function(index) {
		count = count + 1
		$(this).addClass("cols-sort-" + count + "")

		if (count == columns) {
			count = 0
		}
	});
  
  for(var i = 0 + 1; i < columns + 1 ; i++) {
    $(".cols-columns").append("<div class='cols-"+ i +"'></div>")
    $(".cols-sort-" + i).appendTo(".cols-" + i);
  }
}

// ****************************************
// SLICK SLIDERS
// ****************************************
function slickSliders() {
	if ($(".js-slider-gallery").length) {
		$('.js-slider-gallery').slick({
			lazyLoad: 'ondemand',
			dots: false,
			arrows: false,
			infinite: true,
			slidesToShow: 1,
			centerMode: true,
			variableWidth: true,
			autoplay: true,
			autoplaySpeed: 0,
			speed: 10000,
			cssEase: 'linear'
		});
	}

	if ($(".js-testimonials").length) {
		$('.js-testimonials').slick({
			dots: true,
			arrows: false,
			infinite: true,
			slidesToShow: 2,
			slidesToScroll: 1,
			centerMode: true,
			responsive: [
				{
					breakpoint: 900,
					settings: {
						slidesToShow: 1
					}
				}
			]
		});
	}
}


// ****************************************
// inViewport
// Adds & removes an active class to the target element when it enters the viewport
// ****************************************
function inViewport(targetClass){
	$(targetClass).each(function(){
		var triggerHeight = $(window).innerHeight() / 1.3;
		var divPos = $(this).offset().top;
		var topOfWindow = $(window).scrollTop();
		$(this).addClass("viewport-animate")

		if (divPos <= topOfWindow + triggerHeight ) {
			$(this).addClass("viewport-animate--in");
		} else {
			$(this).removeClass("viewport-animate--in")
		}
	});
}

	
	
// ****************************************
// ## inViewport target classes 
// The elements that are effected by the inViewport function
// ****************************************
function inViewportTargets(){
	inViewport(".hero__heading");
	inViewport(".hero__copy");
	inViewport(".hero__links");
	inViewport(".cards__section-heading");
	inViewport(".cards__item");
	inViewport(".alternating__item");
	inViewport(".cards__section-heading");
	inViewport(".outline-cards__item");
	inViewport(".intro");
	inViewport(".slider-gallery");
	inViewport(".grid__item");
	inViewport(".testimonial__section-heading");
	inViewport(".testimonial__list");
	inViewport(".arch");
	inViewport(".body");
}

$(document).ready(function(){
	inViewportTargets();
	slickSliders();
	popup();
	cols();
});


$(window).scroll(function() {
	inViewportTargets();
});

// $(window).scroll(function() {});

// $(window).resize(function() {});


